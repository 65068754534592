import { useEffect } from "react";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import DashboardComponent from "../../components/Dashboard/DashboardComponent";
import { Box } from "@mui/system";
import EquipmentComponent from "../../components/Equipment/EquipmentComponent";
const Equipment = () => {
  return (
    <>
      <DashboardLayout activeTab={"Home"}>
        <Box className="container">
            <EquipmentComponent />
        </Box>
      </DashboardLayout>
    </>
  );
};
export default Equipment;
