import { Box } from "@mui/material";
import LinksComponent from "../../components/LinksComponent/LinksComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";

const Links = () => {
  return (
    <>
      <DashboardLayout activeTab={"Links"}>
        <Box className="container">
          <Box className="p-x-20 p-y-50">
            <LinksComponent />
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default Links;
