import { Box } from "@mui/material";
import ReconditioningProcessComponent from "../../components/ReconditioningProcessComponent/ReconditioningProcessComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";

const ReconditioningProcess = () => {
  return (
    <>
      <DashboardLayout activeTab={"ReconditioningProcess"}>
        <Box className="container">
          <Box className="p-x-20 p-b-50">
          <ReconditioningProcessComponent />
        </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default ReconditioningProcess;
