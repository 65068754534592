import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import ContactUsComponent from "../../components/ContactUsComponent/ContactUsComponent";
import { Box } from "@mui/system";
const ContactUs = () => {
  return (
    <>
      <DashboardLayout
        activeTab={"ContactUs"}
        subHeaderCustomerContainer={"ContactUs_Container"}
      >
        <Box className="container">
          <Box className="p-x-20 p-b-50">
            <Box
              className="ContactUs_Container"
              maxWidth={
                localStorage.getItem("language") === "fr" ||
                localStorage.getItem("language") === "es"
                  ? "1028px"
                  : "797px"
              }
            >
              <ContactUsComponent />
            </Box>
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default ContactUs;
