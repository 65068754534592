import { Box } from "@mui/system";
import AboutUsComponent from "../../components/AboutUsComponent/AboutUs";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";

const AboutUs = () => {
  return (
    <>
      <DashboardLayout activeTab={"AboutUs"}>
        <Box className="container">
          <Box className="p-x-20 p-b-50">
            <AboutUsComponent />
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default AboutUs;
