import { Box } from "@mui/material";
import GalleryComponent from "../../components/GalleryComponent/GalleryComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";

const Gallery = () => {
  return (
    <>
      <DashboardLayout
        activeTab={"Gallery"}
        subHeaderCustomerContainer={"Gallery_Container"}
      >
        <Box className="container">
          <Box className="p-x-20 p-y-50">
            <Box
              className="Gallery_Container"
              maxWidth={
                localStorage.getItem("language") === "fr" ||
                localStorage.getItem("language") === "es"
                  ? "1028px"
                  : "797px"
              }
            >
              <GalleryComponent />
            </Box>
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default Gallery;
