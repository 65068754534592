import { Box } from "@mui/material";
import OurTeamComponent from "../../components/OurTeamComponent/OurTeamComponent";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";

const OurTeam = () => {
  return (
    <>
      <DashboardLayout activeTab={"OurTeam"}>
        <Box className="container">
          <Box className="p-x-20 p-y-50">
            <OurTeamComponent />
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};
export default OurTeam;
